import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mk-user',
  templateUrl: './mk-user.component.html',
  styleUrls: ['./mk-user.component.scss'],
})
export class MkUserComponent implements OnInit {

  @Input() name: string;
  @Input() tooltipDirection: string = 'top';
  @Input() color: string = 'primary';
  @Input() tooltip: boolean = true;

  constructor() {}

  ngOnInit(): void {}

}
