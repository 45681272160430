import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MkImgGroupComponent } from './mk-img-group/mk-img-group.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { MkUserComponent } from './mk-user/mk-user.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MkStatusComponent } from './mk-status/mk-status.component';
import { MKLoaderComponent } from './mk-loader/mk-loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidateFormsDirective } from '../directives/validate-forms.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { CredentialsModalComponent } from './credentials-modal/credentials-modal.component';
import { WeeklyCalendarComponent } from './weekly-calendar/weekly-calendar.component';
import { MonthlyCalendarComponent } from './monthly-calendar/monthly-calendar.component';
import { DynamicCalendarComponent } from './dynamic-calendar/dynamic-calendar.component';
import { MetricsModalComponent } from './metrics-modal/metrics-modal.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  declarations: [
    MkImgGroupComponent, 
    MkUserComponent, 
    MkStatusComponent, 
    MKLoaderComponent, 
    CredentialsModalComponent, 
    WeeklyCalendarComponent, 
    MonthlyCalendarComponent, 
    DynamicCalendarComponent, 
    MetricsModalComponent
  ],
  exports: [
    MkImgGroupComponent, 
    MkUserComponent, 
    MkStatusComponent, 
    MKLoaderComponent, 
    CredentialsModalComponent,
    WeeklyCalendarComponent,
    MonthlyCalendarComponent,
    DynamicCalendarComponent,
    MetricsModalComponent

  ],
  imports: [
    CommonModule,
    PipesModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxDaterangepickerMd.forRoot(),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    DirectivesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  providers:[
    ValidateFormsDirective
  ]
})
export class ComponentsModule {}
