import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { EvaluationsMetricsService } from 'src/app/services/http/evaluations-metrics.service';
import { GoalsService } from 'src/app/services/http/goals.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'metrics-modal',
  templateUrl: './metrics-modal.component.html',
  styleUrls: ['./metrics-modal.component.scss']
})
export class MetricsModalComponent implements OnInit {
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public DT_metrics: MatTableDataSource<any>;
  public metricForm: FormGroup;
  public isLoadingMetrics = false;
  public edit: boolean = false;
  public textlength:number = 0;
  public _metricSelected = null;
  public icons:any [] = [];
  public columnsItemTarget: string[] = ['name', 'driverName','description', 'action'];
  public isLoadingItem = false;
  public editing = false;
  public driversList = [];
  constructor( 
    private formBuilder: FormBuilder,
    public alertService: AlertService,
    public evaluationsMetrics: EvaluationsMetricsService,
    public goalsService: GoalsService
  ) 
  { 
    this.DT_metrics = new MatTableDataSource();
  }

  async ngOnInit(){
    this.metricForm = this.formBuilder.group({
      id: [null],
      name: [null, Validators.compose([Validators.required, Validators.maxLength(60)])],
      description:[null, Validators.compose([Validators.required])],
      driverId: [null, Validators.compose([Validators.required])]
    });
      
    await this.getMetrics();
    await this.getDrivers();
  }

  public close(): void { this.onClose.emit() }

  public asigntextlength(event){
    this.textlength = event.length;    
  }

  public getMetrics(){
    try {
      this.isLoadingMetrics = true;
      this.evaluationsMetrics.getMetrics().then((resp: any) => {
        this.isLoadingMetrics = false;
        this.DT_metrics.data = resp;

        this.DT_metrics.data.forEach(element => {
          element.driverName = element?.driver?.name
        })

        console.log(this.DT_metrics.data);
        

        this.DT_metrics.paginator = this.paginator;
        this.DT_metrics.sort = this.sort;

      });
    } catch (error) {
      console.log(error);
      this.isLoadingMetrics = false
    }
  }

  public async Save(){    
      if (this.metricForm.valid) {
        if (this._metricSelected?._id) {
            try {
              this.isLoadingMetrics = true;
              this.evaluationsMetrics.updateMetrics(this.metricForm.value).then(async(resp: any) => {
                this.alertService.successToast("Metrico actualizado");
                await this.getMetrics();
                this.isLoadingMetrics = false;
                this.onUpdate.emit();// Actualiza lista de metricos

                // Reset de formulario
                this.metricForm.reset();
                this._metricSelected = null;
                this.editing = false;
                // --------------------
              });
            } catch (error) {
              console.log(error);
              this.isLoadingMetrics = false
            }
        }else{
            try {
              this.isLoadingMetrics = true;
              this.evaluationsMetrics.createMetrics(this.metricForm.value).then(async(resp: any) => {
                this.alertService.successToast("Metrico creado");
                
                this.metricForm.reset();
                //this.onUpdate.emit();// Actualiza lista de metricos
                this.isLoadingMetrics = false;

                await this.getMetrics();
              });
            } catch (error) {
              console.log(error);
              console.log("Truena en consulta");
              
              this.isLoadingMetrics = false
            }
        }
        }else{
          this.alertService.infoToast("Campos incompletos");
        }
  }

  public editMetric(metric){
    this.editing = true;
    this._metricSelected = metric

    this.metricForm.patchValue({
      id: metric?._id,
      name: metric?.name,
      driverId: metric?.driverId,
      description: metric?.description
    });
  }

  // public deleteDriver(driver){
  //   try {
      
  //   } catch (error) {
      
  //   }
  // }

  public cancelEdit (){
    this.editing = false;
    this._metricSelected = null;
    this.metricForm.reset();
  }

  public searchDriver(event: any) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.DT_metrics.filter = filterValue.trim().toLowerCase();
  }

  public getDrivers(){
    try {
      this.goalsService.getDrivers().then((resp: any) => {
        this.driversList = resp;
      });
    } catch (error) {
      console.log(error);
    }
  }

}







