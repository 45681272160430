import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {

  constructor(
    private httpService: HttpService
  ) { }
  // <---------------- DRIVERS -------------------->
  public getDrivers = (): Promise<any> => this.httpService.get('api/drivers').toPromise();
  public createDriver = (data:any): Promise<any> => this.httpService.post('api/drivers',data).toPromise();
  public updateDriver = (data:any): Promise<any> => this.httpService.put('api/drivers',data).toPromise();

  // <---------------- GOALS ----------------------->

  public getGoals = (): Promise<any> => this.httpService.get('api/goals').toPromise();
  public createGoals = (data:any): Promise<any> => this.httpService.post('api/goals',data).toPromise();
  public updateGoals = (data:any): Promise<any> => this.httpService.put('api/goals',data).toPromise();
  public getGoalDetail = (_id:string): Promise<any> => this.httpService.get(`api/goals/${_id}`).toPromise();
 
  // <---------------- CONFIGS ----------------------->

  public getConfigs = (): Promise<any> => this.httpService.get('api/config/evaluationsConfig').toPromise();

  // <---------------- BITACORA ----------------------->
   //? CONSULTA TODOS LOS OBJETIVOS
  public getGoalBinnacle = (id: string): Promise<any> => this.httpService.get(`api/goals/binnacle/${id}`).toPromise();
  
  // <---------------- SEGUIMIENTO GOALS ----------------------->
  //Api muy completa que retorna el resumen de seguimiento de todos los periodos y los detalles de evaluaciones individuales
  // De momento no se usa pero se planea usar para Reportes o detalles a Mayor escala;
  public getFiltredAdvances = (data:any): Promise<any> => this.httpService.post('api/goals/tracking',data).toPromise();
}
