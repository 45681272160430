import { HostListener, Renderer2 } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';

@Directive({
  selector: '[validControl]'
})
export class ControlValidateDirective {
  
  @Input() validControl;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ){  }

  ngOnInit(){ }

  @HostListener("input") valid(){
    if(this.validControl.touched == true && this.validControl.value != ""){
      this.element.nativeElement.setAttribute("class",`form-control ${this.validControl.valid ? "":"parsley-error"}`);    
    // this.renderer.setAttribute(this.element.nativeElement,"", '')
    }    
  }

}
