<div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Metricos</h6>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pb-0 mb-1">
    <mk-loader *ngIf="isLoadingMetrics" class="loader-spinner"></mk-loader>

    <form [formGroup]="metricForm">
      <div class="row">
        <div class="col-12 mb-2">
          <div class="row">
            <div class="col-5">
              <h6>Nombre <span class="text-danger">*</span></h6>
            </div>
            <div class="col-7">
              <h6 class="ml-3">Driver <span class="text-danger">*</span></h6>
            </div>
          </div>

          <div class="input-group">
            <input type="text" maxlength="60" formControlName="name" class="form-control text-transform" placeholder="Nombre">
            <ng-select class="form-control w-20" [items]="driversList"  bindValue="_id" bindLabel="name" [clearable]="false" formControlName="driverId"  placeholder="Driver"></ng-select>

            <div class="input-group-append">
              <button class="btn btn-{{editing ? 'info' : 'primary'}} d-flex" (click)="Save()" [title]="editing ? 'Editar Driver' : 'Guardar Métrico'"><i class="fa fa-{{editing ? 'pen' : 'save'}} m-auto"></i>&nbsp;  {{editing ? 'Editar' : 'Guardar'}}</button>
              <button *ngIf="editing" class="btn btn-dark d-flex" (click)="cancelEdit()" title="Cancelar"><i class="fa fa-rotate-left m-auto"></i></button>
            </div>
            <div class="invalid-feedback">Campo inválido</div>
            <!-- <ng-container *ngIf="edit">
              <button type="button" class="btn btn-warning" (click)="cancelEdit()"><i class="fa fa-times"></i></button>
              <button type="button" class="btn btn-primary" (click)="updateCategory(category)"><i class="fa fa-floppy-o mr-2"></i> Guardar</button>
            </ng-container> -->

          </div>
        </div>

        <div class="col-12 mb-2">
            <span class="d-flex justify-content-between">
                <h6>Descripción <span class="text-danger">*</span></h6>
                <span class="text-primary">{{textlength}}/250</span>
              </span>
                <textarea validateAlert [control]="metricForm?.controls?.description" name="" rows="4" class="form-control" formControlName="description" placeholder="Aa..."
                  style="height: 108px; resize: none;" (input)="asigntextlength($event?.target?.value)" maxlength="250">
                </textarea>
        </div>
      </div>
      <div class="row">
        <!-- <h5 class="pl-3 text-info">Lista de Metricos</h5> -->
        <div class="col-xl-7 text-info col-lg-6 col-sm-12 tx-18 pt-1"><b>Lista de Métricos</b></div>

          <div class="col-xl-5 col-lg-6 col-sm-12">
            <div class="input-group mb-1">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="fe fe-search lh--9 op-6"></i>
                    </div>
                </div>
                <input type="search" class="form-control" placeholder="Buscar..." (keyup)="searchDriver($event)">
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card-body p-0">
            <mk-loader *ngIf="isLoadingItem" class="loader-spinner"></mk-loader>
            <div class="table-responsive">
                <table mat-table [dataSource]="DT_metrics" matSort  class="table table-bordered table-striped mb-0 material-table-container" >
          
                    <ng-container matColumnDef="name">
                        <th mat-header-cell width="200px" *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td mat-cell *matCellDef="let item" class="text-overflow">
                            {{item.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="driverName">
                      <th mat-header-cell width="150px" *matHeaderCellDef mat-sort-header> Driver </th>
                      <td mat-cell *matCellDef="let item" class="text-overflow">
                          {{item?.driverName }}
                      </td>
                  </ng-container>
        
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef  width="100%" mat-sort-header class="text-center"> Descripción </th>
                        <td mat-cell *matCellDef="let item" class="text-overflow">
                           {{item.description}}
                        </td>
                    </ng-container>
        
                    <ng-container matColumnDef="action">
                      <th mat-header-cell width="80px" *matHeaderCellDef class="text-center"> Acción </th>
                      <td mat-cell *matCellDef="let item" class="text-center">
                        <div class="text-center">
                            <button (click)="editMetric(item)" class="btn btn-info"><i class="zmdi zmdi-edit"></i></button>
                            <!-- <button (click)="deleteDriver(item)" class="btn btn-danger ml-2"><i class="fa fa-trash"></i></button> -->

                        </div>
                      </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="columnsItemTarget"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsItemTarget;"></tr>
        
                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell text-center" colspan="10">{{isLoadingItem?"Cargando ...":"Sin datos encontrados"}}</td>
                    </tr>
                </table>
            </div>
            <mat-paginator pageSize="10" aria-label="" showFirstLastButtons ></mat-paginator>
          </div>
        </div>
      </div>

    </form>
  </div>

  <div class="modal-footer justify-content-end">
    <button  type="button" class="btn btn-success" (click)="close()">Aceptar</button>
  </div>