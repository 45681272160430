import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'status'
})

/**
 * Pipe para transformar status.
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Importar PipesModule en el módulo donde quieras tranformar status
 * 2) Utilizar el pipe 'status' en el html de tu componente
 */

export class statusPipe implements PipeTransform {

    public lenguages: ILanguage = {
        es: {
            APPROVED: "APROBADO",
            REJECTED: "RECHAZADO",
            PENDING: "PENDIENTE",
            CANCELLED: "CANCELADO",
            INPROGRESS: "EN PROCESO",
            DONE: "COMPLETADO",
            ACCESS: "ACCESO",
            CAFETERIA: "CAFETERIA",
            LOST: "PERDIDA",
            REPLACEMENT: "REMPLAZO",
            MOBILE: "MÓVIL",
            TABLET: "TABLET",
            ACTIVE: "ACTIVO",
            INACTIVE: "INACTIVO",
            VISIBLE: "VISIBLE",
            NOVIVIBLE: "NO VISIBLE",
            DENIED: "DENEGADO",
            ACCEPT: "PERMITIDO",
            PREVENTIVE: "PREVENTIVO",
            VACATION: "VACACIONES",
            PERMISSION: "PERMISO",
            LEAVEDAY: "PERMISO POR DIA",
            LEAVEHOUR: "PERMISO POR HORA",
            WIFI: "WIFI",
            CELLULAR: "Datos Moviles",
            NONE: "Sin conexion",
            UNKNOWN: "Desconocido",
            NUEVO: "NUEVO",
            ANALIZANDO: "ANALIZANDO",
            TRABAJANDO: "TRABAJANDO", 
            RESUELTO: "RESUELTO",
            LIBERADO: "LIBERADO",
            CREATED:"CREADO",
            CLOSED: "CERRADO",
            DELETED: "ELIMINADO",
            LOWER: "MENOR",
            GREATER: "MAYOR",
            LOWEROREQUAL: "MENOR O IGUAL",
            GREATEROREQUAL: "MAYOR O IGUAL",
            EQUAL: "IGUAL",
            NOTEQUALS: "DIFERENTE",
            AUTHORIZED:"AUTORIZADA"

        },
        en:{
            APPROVED: "APPROVED",
            REJECTED: "REJECTED",
            PENDING: "PENDING",
            CANCELLED: "CANCELLED",
            INPROGRESS: "IN PROGRESS",
            DONE: "DONE",
            ACCESS: "ACCESS",
            CAFETERIA: "CAFETERIA",
            LOST: "LOST",
            REPLACEMENT: "REPLACEMENT",
            MOBILE: "MOBILE",
            TABLET: "TABLET",
            ACTIVE: "ACTIVE",
            INACTIVE: "INACTIVE",
            VISIBLE: "VISIBLE",
            NOVIVIBLE: "NOT VISIBLE",
            DENIED: "DENIED",
            ACCEPT: "ACCEPT",
            PREVENTIVE: "PREVENTIVE",
            VACATION: "VACATION",
            PERMISSION: "PERMISSION",
            NUEVO: "NEW",
            ANALIZANDO: "ANALYZING",
            TRABAJANDO: "WORKING", 
            RESUELTO: "RESOLVED",
            LIBERADO: "RELEASED",
        }
    }
    constructor() {}

    transform(value: string, locale: string = "es"): string {
        return value? this.lenguages[locale][value.toUpperCase()] || value :"" ;
    }

}

interface ILanguage{
    en?: any,
    es?: any,
}

