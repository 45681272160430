import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[validateAlert]'
})
/**
 * Directiva para alertar de campos válidos/no válidos.
 * 
 * INSTRUCCIONES DE USO:
 * 1) Importar DirectivesModule al módulo donde quieras implementar
 * 2) Configurar las validaciones con FormGroup en tu formulario
 * 3) Poner el atributo -> 'validateAlert' en input para activar la directiva
 * 4) Poner el atributo -> [control]="FORM_A_VALIDAR.controls.NOMBRE_CONTROL"
 */
export class ValidateAlertDirective {

  @Input() control: FormControl /* Control al que vamos a cosultar si es válido */

  constructor() { }

  /* Escuchar evento 'keyup' del <input/> || <select/> || <textarea/> */
  @HostListener('keyup') onInput() {
    this.validate()
  }

  /* Escuchar evento 'change' del <input/> || <select/> || <textarea/> */
  @HostListener('change') onChange() {
    this.sanitize()
  }

  @HostBinding('class.is-valid') valid = false /* Agregar clase 'is-valid' al <input/> || <select/> || <textarea/> */
  @HostBinding('class.is-invalid') invalid = false /* Agregar clase 'is-invalid' al <input/> || <select/> || <textarea/> */

  ngOnInit() {
  }

  /**
   * Quitar espacios
   */
  sanitize() {
    if (typeof this.control.value == "string") {
      this.control.setValue(this.control.value.trim())
    }
    this.validate()
  }

  /**
   * Validar si el control es válido.
   */
  validate() {
    if (this.control.valid) { /* ¿Control es válido? */
      this.valid = true
      this.invalid = false
    } else if (this.control.value != null) { /* Cuando el formulario carga por primera vez, los valores de los campos son null */
      this.invalid = true
      this.valid = false
    }
  }

}