import { ControlValidateDirective } from './control-validate.directive';
import { NgModule } from '@angular/core';
import { ValidateAlertDirective } from './validate-alert.directive';
import { ValidateFormsDirective } from './validate-forms.directive';

@NgModule({
  declarations: [		
    ControlValidateDirective,
    ValidateAlertDirective,
      ValidateFormsDirective
   ],
  exports: [
    ControlValidateDirective, 
    ValidateAlertDirective,
    ValidateFormsDirective
  ],
  imports: [ ],
})
export class DirectivesModule { }
