import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluationsMetricsService {

  constructor(    private httpService: HttpService  ) { }

  public getMetrics = (): Promise<any> => this.httpService.get('api/evaMetrics').toPromise();
  public createMetrics = (data:any): Promise<any> => this.httpService.post('api/evaMetrics',data).toPromise();
  public updateMetrics = (data:any): Promise<any> => this.httpService.put(`api/evaMetrics`,data).toPromise();
  public getMetricSelect = (): Promise<any> => this.httpService.get('api/evaMetrics/select').toPromise();

}
